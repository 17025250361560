import React, { useEffect } from 'react';
import 'antd/dist/antd.less';
import { configureAmplify } from '@campus/shared';
import { isLoggedIn } from '../components/Auth/AppUser';
import { navigate } from 'gatsby';
// import '../scss/styles.scss';
configureAmplify();

const App = () => {
  const user = isLoggedIn();

  useEffect(() => {
    if (user && user.userGroups.includes('admins')) navigate(`/app/admin`);
    if (user && user.userGroups.includes('providers'))
      navigate('/app/provider');
    else navigate('/app/signin');
  }, []);

  return null;
};

export default App;
